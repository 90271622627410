<template>
  <div>
    <v-row>
      <v-col>
        <v-card flat tile>
          <v-card-title>
            {{ $t("chooseBrand") }}
          </v-card-title>
          <div class="container">
            <choiceOptions
              :options="data"
              @chooseThisOption="chooseThisBrand"
            />
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ajax from "@/services/service.oryx.js";
import choiceOptions from "@/components/ChoiceOptions";

export default {
  components: {
    choiceOptions,
  },
  data() {
    return {
      data: null,
      loading: true,
    };
  },
  mounted() {
    this.getBrands();
  },
  methods: {
    getBrands() {
      let lc = "NL"; //localStorage.getItem("language");

      this.loading = true;
      ajax
        .getBrandChoices(lc)
        .then((res) => {
          // console.log(res.data);
          this.data = res.data;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    chooseThisBrand(brand) {
      this.$store.commit("resetSelectedSolution");
      this.$store.commit("resetSolutionSelectorChoices");
      this.$store.commit("addBrand", brand);

      this.$router.push({
        name: "ProductChoice",
      });
    },
  },
};
</script>

<style scoped>
.wrapper {
  height: 100vh;
  overflow-y: auto;
}
.wrapper_options {
  --auto-grid-min-size: 20rem;
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--auto-grid-min-size), 1fr)
  );
}
.wrapper_content_taxonomy {
  /*--auto-grid-min-size: 25rem;*/
  display: grid;

  grid-template-columns: 1fr 5fr;
  box-shadow: 0 1px 1px 0px rgba(0, 0, 0, 0.05);
  border-radius: 1px;
  transition: 0.2s all;
}

.wrapper_options:hover .wrapper_content_taxonomy {
  /* filter:blur(3px); */
  opacity: 0.8;
  transform: scale(0.98);
  box-shadow: none;
}
.wrapper_options:hover .wrapper_content_taxonomy:hover {
  transform: scale(1);
  filter: blur(0px);
  opacity: 1;
  box-shadow: 0 8px 20px 0px rgba(0, 0, 0, 0.125);
}
/* start solutions */
.wrapper_solutions {
  --auto-grid-min-size: 20rem;
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--auto-grid-min-size), 1fr)
  );
}

.wrapper_content_solution {
  box-shadow: 0 1px 1px 0px rgba(0, 0, 0, 0.05);
  border-radius: 1px;
  transition: 0.2s all;
}

.wrapper_solutions:hover .wrapper_content_solution {
  /* filter:blur(3px); */
  opacity: 0.8;
  transform: scale(0.98);
  box-shadow: none;
}
.wrapper_solutions:hover .wrapper_content_solution:hover {
  transform: scale(1);
  filter: blur(0px);
  opacity: 1;
  box-shadow: 0 8px 20px 0px rgba(0, 0, 0, 0.125);
}
/* end solutions */
.option {
  border-radius: 5px;
  padding: 1em;
  height: 100%;
}
.option .image {
  padding: 0;
}
.wrapper_content_taxonomy {
  padding: 0em;
}

.solution {
  border-radius: 5px;
  padding: 1em;
  height: auto;
  min-height: 88px;
}
.sticky_solutionSelector {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0px;
  z-index: 10;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}
</style>
